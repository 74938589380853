import type {ModalType} from "~/types";

const state : { type: ModalType, show: boolean } = reactive({
    type: 'menu',
    show: false
})

export default () => {
    const showModal = ( type?: ModalType)=> {
        if (type) {
            state.show = true
            state.type = type
        }
    }

    // const hideModal = ()=> {
    //     state.show = false
    // }

    return { showModal, state }
}
